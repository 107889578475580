import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ScheduleRequest } from '@dr-customer-offers-ui/lib-interfaces';
import { NgxIntervalDataGridRowModel, NgxIntervalDataGridService, Week, WeekDays } from '@ngx-interval-data-grid';
import * as moment from 'moment';
import { BulkType, TableDataTypes } from 'ngx-interval-data-grid';
import { Observable, Subscription } from 'rxjs';
import { BulkInput, GroupedData, HeaderButtonTypeEnum, HeaderButtpnType, InputTypeEnum } from '../../../shared/models';
import { UIState } from '../../../shared/models/utility';
import { DataModelService } from '../../../shared/services/data-model.service';
import { DataViewModelService } from '../../../shared/services/data-vm.service';
import { InternalService } from '../../../shared/services/internal.service';
import { MessageService } from '../../../shared/services/message.service';
import { MixPanelService } from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-schedule-table',
  templateUrl: './schedule-tab-table.component.html',
  styleUrls: ['./schedule-tab-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleTabTableComponent implements OnInit, OnDestroy {
  @Input()
  set groupedData(g: GroupedData | null) {
    this._groupedData = g;
    if (!g) return;
    this.timezoneAbbr = moment.tz(g.regConfig ? g.regConfig.timeZone : 'UTC').zoneName();
    this.dataSource.data = g.values ?? [];
  }

  get groupedData(): GroupedData | null {
    return this._groupedData;
  }

  subs = new Subscription();
  UIState = UIState;
  public bindingValue!: number;
  public editMode$: Observable<boolean> = this.internalService.editMode$;
  private _groupedData!: GroupedData | null;
  private onClick$: Observable<HeaderButtpnType | null> = this.internalService.getOnChange$;
  public timezoneAbbr!: string;

  // Define column headers and fields
  columns: string[] = WeekDays;
  displayedColumns: string[] = ['time_period', ...this.columns];
  serverData: NgxIntervalDataGridRowModel[] = [];
  public dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);

  constructor(
    private ngxService: NgxIntervalDataGridService,
    private dataModelService: DataModelService,
    private dataVMService: DataViewModelService,
    private internalService: InternalService,
    private mixPanelService: MixPanelService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.mixPanelService.viewTab('Schedule');
    this.applyBulk();
    this.subs.add(this.onClick$.subscribe((o) => {
      if (o === HeaderButtonTypeEnum.CONFIRM) {
        this.save();
        this.internalService.setOnChange(null);
      } else if (o === HeaderButtonTypeEnum.CANCEL) {
        this.cancel();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * This method is updated each time you enter a value on any of the input field
   * @param element provides the whole element which is being updated
   * @param event is an KeyboardEvent where we get the value entered.
   * @param week is used to provide the week which we are updating the value for.
   */
  inputToCellValue(element: NgxIntervalDataGridRowModel, inputEvent: KeyboardEvent | undefined, week: string) {
    const target = inputEvent?.target as HTMLInputElement | null;
    const value: number | null = target?.valueAsNumber === undefined || isNaN(target.valueAsNumber) ? null : target?.valueAsNumber;
    const oldData: NgxIntervalDataGridRowModel = this.ngxService.getUntouchedDataFromServer(TableDataTypes.SCHEDULE).find(f => f.timePeriodKey === element.timePeriodKey) as NgxIntervalDataGridRowModel;
    const nullReset = oldData[week as Week].value === null  && value === null;
    const isValid = nullReset || (value !== null && value <= (this.groupedData?.regConfig?.maximumValue ?? 0) && value >= (this.groupedData?.regConfig?.minimumValue ?? 0));

    const updatedDataElement = this.ngxService.updateElementValue({
      weekName: week as Week,
      timePeriodKey: element.timePeriodKey,
      value,
      invalid_value: !isValid,
      tableDataType: TableDataTypes.SCHEDULE
    });
    this.serverData  = [...updatedDataElement];
  }

  inputToCellOptOut(element: NgxIntervalDataGridRowModel, isChecked: boolean, week: string) {
    const updatedDataElement = this.ngxService.updateElementOptOut({
      weekName: week as Week,
      timePeriodKey: element.timePeriodKey,
      opt_out: isChecked,
      tableDataType: TableDataTypes.SCHEDULE
    });
    this.serverData  = [...updatedDataElement];
  }

  /**
   * This method is used when user cancels from edit mode. Here we forget all his previous updates and take data we recieved from the server.
   */
  cancel() {
    this.ngxService.clearUnSavedData();
     this.dataSource.data = this.ngxService.getUntouchedDataFromServer(TableDataTypes.SCHEDULE);
  }

  /**
   * This is the main method used to save the updated data and switch back to the readonly mode
   */

  save() {
    console.warn(' this.differencesData', this.ngxService.unsavedData);

    this.internalService.editMode$.next(false);
    const scheduleToPost: ScheduleRequest[] =
    this.dataModelService.getScheduleToPost(this.ngxService.unsavedData, this.groupedData);
    this.postSchedule(scheduleToPost);
  }

  // Check if NgxIntervalDataGridRowModel[] had value before. Return true is it has any value and false is everything is null
  private hasAnyValue(): boolean {
    if (!this.groupedData?.values) {
        return false;
    }
    return this.groupedData.values.some((row: NgxIntervalDataGridRowModel) =>
        row.Monday.value !== null ||
        row.Tuesday.value !== null ||
        row.Wednesday.value !== null ||
        row.Thursday.value !== null ||
        row.Friday.value !== null ||
        row.Saturday.value !== null ||
        row.Sunday.value !== null
    );
}

  postSchedule(schedule: ScheduleRequest[]) {
    const regId = schedule.length ? schedule[0].registration_id : '';
    // Refresh data when success or when error (will also clearUnSavedData)
    this.dataVMService.postSchedule(schedule, regId, !this.hasAnyValue())
      .subscribe({
        next: () => {
          this.messageService.handleSuccess("Successfully submitted schedule.")
          this.dataVMService.refreshData(TableDataTypes.SCHEDULE)
        },
        error: () => this.dataVMService.refreshData(TableDataTypes.SCHEDULE)
      });
  }

  applyBulk(): void {
    this.subs.add(this.internalService.getBulkInputs$.subscribe((b: BulkInput | null) => {
      if (!b) return;
      switch (b.bulkType) {
        case BulkType.CUSTOM:
          {
            if (b.inputType === InputTypeEnum.VALUE && (b.value !== null && b.value !== undefined)) {
              this.serverData = [...this.ngxService.updateValueBulk(BulkType.CUSTOM, b.value, TableDataTypes.SCHEDULE, undefined, moment(b.startDateAndTime), moment(b.endDateAndTime))];
            } else if (b.opt_out)
              this.serverData = [...this.ngxService.updateOptOutBulk(BulkType.CUSTOM, b.opt_out, TableDataTypes.SCHEDULE, undefined, moment(b.startDateAndTime), moment(b.endDateAndTime))];
          }
          break;
        case BulkType.DAY:
          if (b.value !== null && b.value !== undefined) this.serverData = [...this.ngxService.updateValueBulk(BulkType.DAY, b.value, TableDataTypes.SCHEDULE, b.week)];
          break;
        case BulkType.WEEK:
          if (b.value !== null && b.value !== undefined) this.serverData = [...this.ngxService.updateValueBulk(BulkType.WEEK, b.value, TableDataTypes.SCHEDULE)];
          break;
      }
      console.warn('this.serverData', this.serverData);
      this.dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);
      this.cdr.detectChanges();
    }));
  }
}
