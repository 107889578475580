import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { DataViewModelService } from '../services/data-vm.service';

@Pipe({
  name: 'suffixDate',
  standalone: true,
  pure: true
})
export class SuffixDatePipe implements PipeTransform {
  constructor(private dataVMService: DataViewModelService) {}
  transform(day: number): string {
    moment.locale(this.dataVMService.locale);
    return moment(day, 'D').format('Do');
  }
}
