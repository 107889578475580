import { Injectable } from '@angular/core';
import { Timezone } from 'common-utils/dist/models/time';
import * as moment from 'moment';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { DaterangeISO, SelectedDateRange } from '../models/daterange';

@Injectable({ providedIn: 'root' })
export class DateService {
  getISODate(dateRange: NgxDateRangePickerOutput, timezone: string): DaterangeISO {
    const tz = new Timezone(timezone);

    const startDate: moment.Moment = moment(dateRange.start);
    const endDate: moment.Moment = moment(dateRange.end);
    const shortTz = tz.getOffset(startDate.format());

    // Get the start and end of the week for the given date range in the timezone
    // No changing on startOfWeek
    const startOfWeek = moment.utc(startDate.startOf('week').utcOffset(shortTz.stringOffset, true)).toISOString();
    let endOfWeek = moment.utc(endDate.endOf('week').utcOffset(shortTz.stringOffset, true)).toISOString();

    // To check if it's a DST week
    const isStartDateDst: boolean = moment(startOfWeek).tz(timezone).isDST();
    const isEndDateDst: boolean = moment(endOfWeek).tz(timezone).isDST();
    const isDstWeek: boolean = isStartDateDst !== isEndDateDst;

    if (isDstWeek) {
        if (isEndDateDst) {
            // If the end date is in DST, subtract 1 hour to the end date
            endOfWeek = moment(endOfWeek).subtract(1, 'hours').toISOString();
        } else {
            // If the start date is in DST, add 1 hour to the end date
            endOfWeek = moment(endOfWeek).add(1, 'hours').toISOString();
        }
    }

    return {
        start: startOfWeek,
        end: endOfWeek
    };
}

    getDaysBetweenDates(dateRange: SelectedDateRange) {
        const days: number[] = [];
        for (let m = dateRange.start; m.isBefore(dateRange.end); m.add(1, 'days')) {
            days.push(m.date());
        }
        return days;
    }
}
