import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegistrationResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) {}
  
  getRegistrations(program_id: string): Observable<RegistrationResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/programs/` + program_id + `/registrations`;
    return this.http.get<APIData<RegistrationResponse[]>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractRegistrationsData(res)),
      catchError(this.handleError),
    );
  }

  getRegistrationsUsingSession(): Observable<RegistrationResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations`;
    return this.http.get<APIData<RegistrationResponse[]>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractRegistrationsData(res)),
      catchError(this.handleError),
    );
  }

  private extractRegistrationsData(res: APIData<RegistrationResponse[]>): RegistrationResponse[] {
    const payload = res.data;
    return payload as RegistrationResponse[];
  }

  private handleError(error: Error | HttpErrorResponse) {
    return throwError(() => error);
  }
}
