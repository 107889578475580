import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaselineRequest,
  BaselineResponse,
  DARequest,
  DAResponse,
  GroupedOffersDataModel,
  OfferRequest,
  OfferResponse,
  OperationalGroup,
  ProgramResponse,
  RegistrationResponse,
  RegistrationViewConfigResponse,
  ScheduleRequest,
  ScheduleResponse
} from '@dr-customer-offers-ui/lib-interfaces';
import {
  BaselineService,
  DeclaredAvailabilityService,
  OfferService,
  ProgramService,
  RegistrationService,
  RegistrationViewConfigService,
  ScheduleService
} from '@dr-customer-offers-ui/lib-services';
import { Timezone } from 'common-utils/dist/models/time';
import * as moment from 'moment';
import { NgxDropdownOption } from 'ngx-dropdown';
import { DataModel, DetailTableCellModel, NgxIntervalDataGridRowModel, NgxIntervalDataGridService, OperationalHours, TableDataType, TableDataTypes } from 'ngx-interval-data-grid';
import { catchError, combineLatest, finalize, forkJoin, map, merge, Observable, of, shareReplay, Subject, switchMap, take } from 'rxjs';
import { DayOfWeekModel, ExportReportOutput, FilterSelectionInterface, GroupedData, RegConfig, transformRegConfig } from '../models';
import { DaterangeISO } from '../models/daterange';
import { UserPreference } from '../models/user-preference';
import { DataModelService } from './data-model.service';
import { DateService } from './date.service';
import { InternalService } from './internal.service';

@Injectable({ providedIn: 'root' })
export class DataViewModelService {
  private refreshOpenTabTrigger$ = new Subject<void>();
  private refreshDATrigger$ = new Subject<void>();
  private refreshBaselineTrigger$ = new Subject<void>();
  private refreshScheduleTrigger$ = new Subject<void>();
  private regViewConfigCache: Map<string, Observable<RegConfig | null>> = new Map<string, Observable<RegConfig | null>>();
  public locale = '';
  constructor(
    private programService: ProgramService,
    private registrationService: RegistrationService,
    private registrationViewConfigService: RegistrationViewConfigService,
    private internalState: InternalService,
    private dateService: DateService,
    private offerService: OfferService,
    private scheduleService: ScheduleService,
    private baselineService: BaselineService,
    private dataModelService: DataModelService,
    private ngxService: NgxIntervalDataGridService,
    private daService: DeclaredAvailabilityService
  ) {}

  getProgramsList(): Observable<NgxDropdownOption[] | null> {
    return combineLatest([this.internalState.getUserPreference, this.programService.getPrograms()]).pipe(
      map(([userPreference, programs]) => {
        let programsData: NgxDropdownOption[] = [];
        if (programs && programs instanceof Array) {
          programsData = programs.map((program: ProgramResponse) => {
            const programsMeta: NgxDropdownOption = {
              value: this.getLocalisedLabel(program.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
              id: program.id
            };
            return programsMeta;
          });
        }
        const sortedProgsData = programsData.length ? programsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedProgsData) this.internalState.setSelectedProgram(sortedProgsData[0]);
        return sortedProgsData && sortedProgsData.length ? sortedProgsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegistrationsList(programId: string): Observable<NgxDropdownOption[] | null> {
    return combineLatest([this.internalState.getUserPreference, this.registrationService.getRegistrations(programId)]).pipe(
      map(([userPreference, registrations]) => {
        if (!registrations || !registrations.length) return null;
        const regsData: NgxDropdownOption[] = registrations.map((registration: RegistrationResponse) => {
          const regsMeta: NgxDropdownOption = {
            value: this.getLocalisedLabel(registration.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
            id: registration.id
          };
          return regsMeta;
        });
        const sortedRegsData = regsData.length ? regsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedRegsData) this.internalState.setSelectedReg(sortedRegsData[0]);
        return sortedRegsData && sortedRegsData.length ? sortedRegsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegistrationsListUsingSession(): Observable<NgxDropdownOption[] | null> {
    return combineLatest([this.internalState.getUserPreference, this.registrationService.getRegistrationsUsingSession()]).pipe(
      map(([userPreference, registrations]) => {
        if (!registrations || !registrations.length) return null;
        const regsData: NgxDropdownOption[] = registrations.map((registration: RegistrationResponse) => {
          const regsMeta: NgxDropdownOption = {
            value: this.getLocalisedLabel(registration.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
            id: registration.id
          };
          return regsMeta;
        });
        const sortedRegsData = regsData.length ? regsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedRegsData) this.internalState.setSelectedReg(sortedRegsData[0]);
        return sortedRegsData && sortedRegsData.length ? sortedRegsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegViewConfig(selectedRegistrationId: string): Observable<RegConfig | null> {
    const cachedObservable: Observable<RegConfig | null> | undefined = this.regViewConfigCache.get(selectedRegistrationId);

    if (cachedObservable) return cachedObservable;

    const vm: Observable<RegConfig | null> = combineLatest([
      this.internalState.getUserPreference,
      this.registrationViewConfigService.getRegistrationViewConfig(selectedRegistrationId)
    ]).pipe(
      map(([userPreference, regConfigResponse]: [UserPreference | null, RegistrationViewConfigResponse]) => {
        if (!regConfigResponse) return null;
        const transformedConfig: RegConfig = transformRegConfig(regConfigResponse, userPreference?.apiLocale ?? '');
        this.internalState.setRegViewConfigState(transformedConfig);
        return transformedConfig;
      }),
      shareReplay(1),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        this.regViewConfigCache.delete(selectedRegistrationId);
        return of(null);
      }),
      finalize(() => {
        this.regViewConfigCache.delete(selectedRegistrationId);
      })
    );

    this.regViewConfigCache.set(selectedRegistrationId, vm);
    return vm;
  }

  getExportOffers(dateRange: ExportReportOutput): Observable<{ offers: OfferResponse[] | null, timeZone: string } | null> {
    return this.internalState.selectedRegViewConfigFilterState.pipe(
        switchMap((regConfig: RegConfig | null) => {

            if (!regConfig) return of(null);
            const tz = new Timezone(regConfig?.timeZone ?? 'UTC');
            const startDate: moment.Moment = moment(dateRange.startMomentDate);
            const endDate: moment.Moment = moment(dateRange.endMomentDate);
            const shortTz = tz.getOffset(startDate.format());

            const selectedDatesISO: DaterangeISO = {
              start:moment.utc(startDate.utcOffset(shortTz.stringOffset, true)).toISOString(),
              end: moment.utc(endDate.utcOffset(shortTz.stringOffset, true)).toISOString()
            }

            return forkJoin({
                offers: this.offerService.getOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end),
                timeZone: of(regConfig.timeZone)
            });
        })
    );
}

  getGroupedDataWithDV(): Observable<GroupedData | null> {
    return merge(
      this.refreshDATrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
                if (!filteredData) return of(null);
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig) return of(null);
            const selectedDatesISO = this.dateService.getISODate(filteredData.dateRange, regConfig.timeZone);
            return this.daService.getDA(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
              map((offers: DAResponse[] | null) => {
                console.warn('offers', offers);

                const offerData: DataModel[] = this.dataModelService.getGridDataFromDA(
                  offers as DAResponse[],
                  regConfig,
                  selectedDatesISO.start,
                  selectedDatesISO.end
                );

                // Cast the start and end date to remove the error "No overload matches this call."
                const startdate: moment.Moment = moment(filteredData.dateRange.start as moment.Moment);
                const enddate: moment.Moment = moment(filteredData.dateRange.end as moment.Moment);

                const dayOfWeek: DayOfWeekModel = {
                  data: offerData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.DECLARED_AVAILABILITIES
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);
                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );
          })
        );
      })
    );
  }

  getGroupedDataWithOffers(): Observable<{ open: GroupedData | null, clear: GroupedData | null }> {
    return merge(
      this.refreshOpenTabTrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
        if (!filteredData) return of({ open: null, clear: null });
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig) return of({ open: null, clear: null });

            const selectedDatesISO = this.dateService.getISODate(filteredData.dateRange, regConfig.timeZone);

            const clear$ = this.offerService.getOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
              map((offers: OfferResponse[] | null) => {
                const offerData: DataModel[] = this.dataModelService.getGridDataFromOffers(
                  offers as OfferResponse[],
                  regConfig,
                  selectedDatesISO.start,
                  selectedDatesISO.end
                );

                const startdate: moment.Moment = moment(filteredData.dateRange.start as moment.Moment);
                const enddate: moment.Moment = moment(filteredData.dateRange.end as moment.Moment);

                const dayOfWeek: DayOfWeekModel = {
                  data: offerData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType:  regConfig.operational_hours ? TableDataTypes.CLEAR : TableDataTypes.OPEN
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);

                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );

            const open$ = regConfig.operational_hours
              ? this.offerService.getGroupedOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
                  map((t: OperationalGroup[] | null) => {
                    const groupedData = this.ngxService.groupByIntervals(
                      t as OperationalGroup[],
                      regConfig.operational_hours as OperationalHours[],
                      TableDataTypes.OPEN,
                      'ON_PEAK', 'OFF_PEAK'
                    );
                    return {
                      values: groupedData,
                      regConfig: regConfig,
                      selectedDateRange: filteredData.dateRange
                    };
                  })
                )
              : clear$;

            return forkJoin({ open: open$, clear: clear$ });
          })
        );
      })
    );
  }


  getGroupedDataWithSchedule(): Observable<GroupedData | null> {
    return merge(
      this.refreshScheduleTrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
        if (!filteredData) return of(null);
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig || !regConfig.tab_display.display_schedule_tab) return of(null);
            return this.scheduleService.getSchedule(regConfig.registrationId).pipe(
              map((schedule: ScheduleResponse[]) => {
                const scheduleData: DataModel[] = this.dataModelService.getGridDataFromSchedule(schedule, regConfig);
                //TODO - remove this
                const scheduleFirstIndex: DataModel = scheduleData[0];
                const scheduleLastIndex: DataModel = scheduleData[1];

                const startdate: moment.Moment = moment(scheduleFirstIndex.offer_start_dttm_utc);
                const enddate: moment.Moment = moment(scheduleLastIndex.offer_end_dttm_utc);

                const dayOfWeek: DayOfWeekModel = {
                  data: scheduleData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.SCHEDULE
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);
                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );
          })
        );
      })
    );
  }

  getGroupedDataWithBaseline(): Observable<GroupedData | null> {
    return merge(
      this.refreshBaselineTrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
        if (!filteredData) return of(null);
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig || !regConfig.tab_display.display_baselines_tab) return of(null);
            const selectedDatesISO = this.dateService.getISODate(filteredData.dateRange, regConfig.timeZone);
            return this.baselineService.getBaselines(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
              map((baseline: BaselineResponse[]) => {
                const baselineData: DataModel[] = this.dataModelService.getGridDataFromBaseline(
                  baseline,
                  regConfig,
                  selectedDatesISO.start,
                  selectedDatesISO.end
                );
                //TODO - remove this
                const baselineFirstIndex: DataModel = baselineData[0];
                const baselineLastIndex: DataModel = baselineData[1];

                const startdate: moment.Moment = moment(baselineFirstIndex.offer_start_dttm_utc);
                const enddate: moment.Moment = moment(baselineLastIndex.offer_end_dttm_utc);

                const dayOfWeek: DayOfWeekModel = {
                  data: baselineData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.BASELINE
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);
                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );
          })
        );
      })
    );
  }

  postOffers(offers: OfferRequest[], regId: string): Observable<OfferResponse[] | null> {
    return this.offerService.putOffers(regId, offers).pipe(
      map((offers: OfferResponse[] | null) => {
        return offers as OfferResponse[];
      })
    );
  }

  putGroupedOffers(offers: GroupedOffersDataModel[], regId: string): Observable<OfferResponse[] | null> {
    return this.offerService.putGroupedOffers(regId, offers).pipe(
      map((offers: OfferResponse[] | null) => {
        return offers as OfferResponse[];
      })
    );
  }

  postDAOffers(offers: DARequest[], regId: string): Observable<DAResponse[] | null> {
    return this.daService.putDAOffers(regId, offers).pipe(
      map((offers: DAResponse[] | null) => {
        return offers as DAResponse[];
      })
    );
  }

  postSchedule(offers: ScheduleRequest[], regId: string, ispost: boolean): Observable<ScheduleResponse[] | null> {
    return !ispost
    ? this.scheduleService.putSchedule(regId, offers).pipe(
      map((offers: ScheduleResponse[]) => {
        return offers as ScheduleResponse[];
      })
    )
    : this.scheduleService.postSchedule(regId, offers).pipe(
      map((offers: ScheduleResponse[]) => {
        return offers as ScheduleResponse[];
      })
    )
  }

  postBaseline(baseline: BaselineRequest[], regId: string): Observable<BaselineResponse[] | null> {
    return this.baselineService.putBaselines(regId, baseline).pipe(
      map((_baseline: BaselineResponse[]) => {
        return _baseline as BaselineResponse[];
      })
    );
  }


  public refreshData(tabName: TableDataType): void {
    this.ngxService.clearUnSavedData();
    this.internalState.editMode$.next(false);
    switch(tabName) {
      case TableDataTypes.OPEN: return this.refreshOpenTabTrigger$.next();
      case TableDataTypes.SCHEDULE: return this.refreshScheduleTrigger$.next();
      case TableDataTypes.BASELINE: return this.refreshBaselineTrigger$.next();
      case TableDataTypes.DECLARED_AVAILABILITIES: return this.refreshDATrigger$.next();
    }
  }

  public cancelEditing(dayOfWeekData: DayOfWeekModel): NgxIntervalDataGridRowModel[] {
    this.ngxService.clearUnSavedData();
    return this.ngxService.groupByDayOfWeek(
      dayOfWeekData.data,
      dayOfWeekData.timeZone,
      dayOfWeekData.tableType
    )
  }

  public lookForDSTDuplication(unsavedOffers: DetailTableCellModel[], timeZone: string): DetailTableCellModel[] {
    const duplicateOffers: DetailTableCellModel[] = [];

            unsavedOffers.forEach((offer: DetailTableCellModel) => {
              if (this.ngxService.isDSTEnd(offer.start_date_time, timeZone)) {
                const duplicatedOffer: DetailTableCellModel = { ...offer };

                const startDate = new Date(duplicatedOffer.start_date_time);
                const endDate = new Date(duplicatedOffer.end_date_time);

                startDate.setUTCHours(startDate.getUTCHours() - 1);
                endDate.setUTCHours(endDate.getUTCHours() - 1);

                duplicatedOffer.start_date_time = startDate.toISOString();
                duplicatedOffer.end_date_time = endDate.toISOString();
                duplicateOffers.push(duplicatedOffer);
              }
            });

            // Add the duplicated offers to the original array or handle them separately as needed
            unsavedOffers.push(...duplicateOffers);
            return unsavedOffers;
  }

  private getLocalisedLabel(displayLabelMap: { [locale: string]: string }, apiLocale: string, fallbackLocale: string): string {
    if (displayLabelMap[apiLocale] !== undefined) {
      return displayLabelMap[apiLocale];
    } else if (displayLabelMap[fallbackLocale] !== undefined) {
      return displayLabelMap[fallbackLocale];
    } else if (displayLabelMap[Object.keys(displayLabelMap)[0]] !== undefined) {
      return displayLabelMap[Object.keys(displayLabelMap)[0]];
    } else {
      return 'Undefined';
    }
  }

}
